<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Désignation</label>
        <input
          type="text"
          :value="stock.fullName"
          class="form-control"
        />
      </div>

      <div class="col-2">
        <label for="">Chambre </label>
        <select name="" id="" class="form-select">
          <option
            v-for="(coldroom, index) in coldrooms"
            :key="index++"
            :value="coldroom.reference"
          >
            {{ coldroom.number }}
          </option>
        </select>
      </div>
      <div class="col-7">
        <label for="">Remarque</label>
        <input type="text" v-model="stock.remark" class="form-control" />
      </div>
    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(stock)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      stock: {},
    };
  },
  computed: {
    
    ...mapGetters("coldroom", {
      coldrooms: "getColdrooms",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("stock/store", data);
    },
  },
  beforeMount() {
    this.$store.dispatch("coldroom/getAll");
  },
};
</script>
